import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import Popup from './Popup';
import { GlobalSettingsProvider } from '../../GlobalSettingsContext';
import { DBConfig } from '../../config';
import { initDB } from 'react-indexed-db-hook';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import '../../styles/global.css';
import '../../styles/tailwind.css';
import './components/PrimaryFeatures.css';
initDB(DBConfig);
const rootElement = document.getElementById('root');
if (rootElement) {
    const rootDiv = ReactDOM.createRoot(rootElement);
    rootDiv.render(_jsx(HelmetProvider, { children: _jsx(GlobalSettingsProvider, { children: _jsx(React.StrictMode, { children: _jsx(Popup, {}) }) }) }));
}
else {
    const root = document.createElement("div");
    document.body.appendChild(root);
    const rootDiv = ReactDOM.createRoot(root);
    rootDiv.render(_jsx(HelmetProvider, { children: _jsx(GlobalSettingsProvider, { children: _jsxs(React.StrictMode, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: "\u6781\u8BD1 - \u60A8\u7684\u4E13\u4E1A\u6587\u732E\u52A9\u624B" }), _jsx("link", { rel: "icon", type: "images/x-icon", href: "/favicon.ico" }), _jsx("meta", { name: "description", content: "\u6781\u8BD1 - \u60A8\u7684\u4E13\u4E1A\u6587\u732E\u52A9\u624B" })] }), _jsx(Popup, {})] }) }) }));
}
